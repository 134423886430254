/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import data
import { cta } from '../data';

const Cta = () => {
  // destructure cta data
  const { title, subtitle } = cta;
  return (
    <section className='section bg-left-top bg-[#000] text-[#fff]' style={{minHeight: 350}}>
      <div className='max-w-[1340px] mx-auto px-[25px]'
        data-aos='fade-up'
        data-aos-delay='200'
      >
        {/* text */}
        <div className='max-w-[920px] mx-auto text-center'>
          <h2
            className='h2 text-[#fff] md:mb-6 mb-3'
          >
            {title}
          </h2>
          <p
            className='text-2xl lg:text-4xl text-[#fff]'
          >
            {subtitle}
          </p>
        </div>
        <div className='flex justify-center'>

          <div className='flex space-x-4'>
            <a href='https://twitter.com/shib_hat' target='_blank'
              className='btn btn-md bg-[#151b28] font-bold text-[#fff] md:mt-[40px] mt-[20px] lg:text-[22px] gap-x-[10px] mx-auto'
            >
              $BABYSIF ON TWITTER
            </a>
            <a href='https://t.me/BBShibwifhatToken' target='_blank'
              className='btn btn-md bg-[#151b28] font-bold text-[#fff] md:mt-[40px] mt-[20px] lg:text-[22px] gap-x-[10px] mx-auto'
            >
              $BABYSIF ON TELEGRAM
            </a>
          </div>

        </div>
      </div>
    </section>
  );
};

export default Cta;
