/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

// import aos
import Aos from 'aos';
// import aos css
import 'aos/dist/aos.css';

// import components
import Tele from './assets/images/telegram.png';
import Twitter from './assets/images/twitter.png';
import Cta from './components/Cta';
import Header from './components/Header';
import RoadMap from './components/Roadmap';
import Shiba from './assets/logo.jpg'
import vid from './assets/vid.mp4'
import { Player } from 'video-react';

const App = () => {

  // initialize aos
  Aos.init({
    duration: 1800,
    offset: 100,
  });
  return (
    <div className='overflow-hidden'>
      <Header />
      <section className='min-h-[950px]'>
        <div className='mx-auto sm:min-h-[950px] md:min-h-[950px] flex justify-center items-center hero' style={{ backgroundSize: "100% 100%" }}>
          <div className='flex flex-col lg:gap-x-[30px] gap-y-8 lg:gap-y-0 lg:flex-row items-center text-center lg:text-left md:px-40 px-4'>
            {/* <img className='w-96 m-auto md:hidden block w-3/4 z-50 mt-12' src={logo} alt='' /> */}
            <div className='w-2/3 m-auto md:hidden block z-50 mt-12'>
        
                <Player autoPlay={true} poster={Shiba}>
                  <source src={vid} autoPlay={true}/>
                </Player>

              </div>
            <div className='flex-1 md:w-1/2'>
              <h3
                className='md:mt-16 mt-5 md:text-4xl text-2xl text-white font-bold mb-2 lg:mb-5 text-center'
                data-aos='fade-down'
                data-aos-delay='300'
              >
                <div>Introducting $BABYSIF <br /> For dog lovers worldwide</div>
              </h3>
              <div
                className='lead text-white mb-5 lg:mb-10 md:text-left text-center'
                data-aos='fade-down'
                data-aos-delay='500'
              >
                🐶  BABY Shibwifhat Token ($BABYSIF) emerges as a cutting-edge cryptocurrency within the Solana blockchain 🪙
                 <br />
              </div>

              <div
                className='flex flex-col max-w-sm lg:max-w-full mx-auto lg:mx-0 gap-x-2 lg:gap-x-6'
                data-aos='fade-up'
                data-aos-delay='500'
              >

                <div className='mt-5 grid md:grid-cols-2 sm:grid-cols-1 gap-8' data-aos='fade-down' data-aos-delay='100'>
                  <a target='_blank' href='https://t.me/BBShibwifhatToken' className="social">
                    <div className='social_title'>TELEGRAM</div>
                    <img className='w-12' src={Tele} alt='' />
                  </a>
                  <a target='_blank' href='https://twitter.com/shib_hat' className="social">
                    <div className='social_title'>TWITTER</div>
                    <img className='w-12' src={Twitter} alt='' />
                  </a>
                </div>

              </div>
            </div>

            <div className='flex-2 justify-end md:w-1/2' data-aos='fade-down' data-aos-delay='700'>
              <div className='w-2/3 m-auto'>
        
                <Player autoPlay={true} poster={Shiba}>
                  <source src={vid} autoPlay={true}/>
                </Player>

              </div>

            </div>
          </div>

        </div>


      </section>

      <div style={{height: 1, background: '#ffffff4d'}} className='container mx-auto my-10' />
      <section id='about' className='lg:min-h-[712px] pt-[3rem] lg:py-[10rem] bg-[#000] text-[#fff]'>
        <div className='container mx-auto'>

          <div className='text-center md:text-6xl text-4xl md:mb-20 mb-10 font-bold' data-aos='fade-up' data-aos-offset='100' data-aos-delay='100'>About $BABYSIF</div>
          <div className='flex flex-col lg:flex-row lg:items-center lg:gap-x-[30px] bg-[#151b28] rounded-xl md:p-10 p-2'>


            <img className='rounded-2xl pb-3 w-4/5 m-auto md:hidden block my-6' src={Shiba} alt='' />
            <div className='flex-1 md:mb-0 mb-6' data-aos='fade-right' data-aos-offset='100' data-aos-delay='300'>

              <div className='text-xl md:text-left text-center'>
              🐶 Seamlessly blending the allure of Shib with the whimsical charm of the wif hat meme. This unique fusion harnesses the power and efficiency of Solana's high-performance blockchain. Our overarching mission is to cultivate a community-driven decentralized ecosystem that champions innovation, inclusivity, and joy within the realm of decentralized finance.
            Revoke, Burn LP 

              </div>
            </div>
            <div className='flex-1' data-aos='fade-left' data-aos-offset='100' data-aos-delay='300'>
              <img className='rounded-2xl pb-3 w-4/5 m-auto md:block hidden' src={Shiba} alt='' />
            </div>
          </div>
        </div>
      </section>
      <div style={{height: 1, background: '#ffffff4d'}} className='container mx-auto my-10' />
      <section id='traits' className='lg:min-h-[712px] pt-[1.5rem] lg:py-[8rem] pb-10'>
        <div className='container mx-auto'>
          <div className='text-center md:text-6xl text-4xl md:mb-20 mb-4 font-bold' data-aos='fade-up' data-aos-offset='100'>$BABYSIF TRAITS</div>
          <div className='grid grid-cols-1 gap-10' data-aos='fade-up' data-aos-offset='200'>
            <div className='howto' data-aos='fade-left' data-aos-offset='300'>
              <div className="flex items-center rounded-xl">
                <div className="text-white" style={{fontSize: 60, margin: 12}}>
                  <i class="fa-solid fa-coins"></i>
                </div>
                <div className=' md:ml-6'>
                  <div className='social_title md:text-3xl text-md'>$BABYSIF X DEX</div>
                  <div className="md:text-xl text-base">Concentrated Liquidity, Active Liquidity, Price Ticks</div>
                </div>
              </div>
            </div>
            <div className='howto' data-aos='fade-left' data-aos-offset='300'>
              <div className="flex items-center">
                  <div className="text-white" style={{fontSize: 60, margin: 12}}>
                    <i class="fa-solid fa-shop-lock"></i>
                  </div>
                  <div className=' md:ml-6'>
                    <div className='social_title md:text-3xl text-md'>$BABYSIF X Marketplace</div>
                    <div className="md:text-xl text-base">The Fastest NFT Marketplace build on Solana</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <div style={{height: 1, background: '#ffffff4d'}} className='container mx-auto md:mt-40 mt-10' />
      <RoadMap />

      <div style={{height: 1, background: '#ffffff4d'}} className='container mx-auto' />
      <Cta />

    </div>
  );
};

export default App;
